const Translation = {
  nav: {
    dashboard: "Dashboard",
    dataset: "Dataset",
    table: "Base Table",
    settings: "Settings",
    workspace_manage: "Workspace",
    user_manage: "User Manage",
    notification: "Notification",
    system_manage: "System Manage",
    official_website: "Official Website",
    change_language: "Language",
    tip: {
      network_available: "network is available",
      network_unavailable: "network is unavailable, please check",
    },
  },
  share: {
    admin: "Manageable",
    view: "View only",
  },
  sheet: {
    set_row_height: "Set Row Height",
    default_height: "Default",
    middle_height: "Middle",
    high_height: "High",
    extremely_high_height: "Extremely High",
    row_height: "Row Height",
    boolean_error:
      "Unrecognized as a boolean value. Please use one of the following options: true/false, yes/no, 1/0.",
    date_error:
      "Unrecognized as date. Please use yyyy-MM-dd or yyyy-MM-dd HH:mm:ss format.",
    add_row: "Add Row",
    exceed_max_row:
      "The maximum number of rows is 10000, please download Excel to view more",
    decimal_format: "Decimal Format",
  },
  table_filter: {
    add_filters: "Add filters",
    count_filters: "{{count}} filters",
    filter: "Filter",
    title: "Filter Config",
    base_table_info:
      "Use filters to quickly filter out excess data through configured conditions, thereby obtaining more accurate data. When the source data of the cardinality data table changes, the filtered data results will be synchronized in real time to the latest data.",
    when: "When",
    and: "And",
    or: "Or",
    equal: "equal to",
    unequal: "not equal to",
    include: "include",
    except: "except",
    null: "empty",
    not_null: "not empty",
    duplicate: "duplication",
    later_than: ">",
    later_than_or_equal_to: ">=",
    earlier_than: "<",
    earlier_than_or_equal_to: "<=",
    greater_than: ">",
    greater_than_or_equal_to: ">=",
    less_than: "<",
    less_than_or_equal_to: "<=",
    yes: "Yes",
    no: "No",
    add_filter: "Add filter conditions",
  },
  dataset: {
    title: "Dataset",
    name: "Name",
    version: "Version",
    versions: "Versions",
    tables: "Tables",
    info: "Base Info",
    blank_table: "Blank Table",
    manage_tasks: "Manage Tasks",
    add_tasks_in_batches: "Add tasks in batches",
    operator: "Operator",
    file_compare: "File Compare",
    select_compare_table_name: "Select compare table name",
    file_compare_tip: "Base table compare is not supported",
    data_setup: "Data Setup",
    file_compare_settings: {
      only_changed: "Only Changed",
      only_greater_than_0: "Only Greater Than 0",
      show_diff_value: "Show Diff in Value",
      show_diff_ratio: "Show Diff in Ratio",
    },
    table_views: {
      select_table: "Please select the table",
      select_table_field: "Please select the table / fields",
    },
    version_info: {
      version: "Version",
      name: "Name",
      description: "Description",
      copy: "Copy previous version data",
      name_existed:
        "Version names under the same data set cannot be repeated, please re-enter.",
      primary_table: "Primary table",
      add_filters: "Add filters",
      count_filters: "{{count}} filters",
      tip: "Dataset support multi-version management, you can view and compare data between versions",
      get_settings: "Get settings",
      table_name: "Select table to copy",
      copy_table_tip:
        "Notice: last settings will be saved, it would be applied in next copying",
    },
    template_import: {
      title: "Import from template",
      all_tables: "All Tables",
      base_tables: "Base Tables",
      base_table: "Base",
      view: "View",
      use: "Use",
      create: "Create from template",
      new_base_table: "Create Base Table",
      link_base_table: "Link Existed Base Table",
      link_field: "Link Field",
      table_name_unique_fail: "Base table with the same name already exists",
    },
    task_status: {
      locked: "Locked",
      unlocked: "Unlocked",
      saved: "Saved",
      pending: "Pending",
      finished: "Finished",
    },
    action_status: {
      title: "Execution Progress",
      failed: "Failed",
      succeeded: "Succeeded",
      running: "Running",
      pending: "Pending",
    },
    action_config: {
      title: "Execution Config",
      pipeline: "Pipeline",
      is_pipeline_blank: "Please select pipeline first",
    },
    action_record: {
      title: "Records",
      push_record: "Execution Record",
      action_record: "Operation Record",
      version: "Version",
      start_time: "Start Time",
      end_time: "End Time",
      operator: "Operator",
      status: "Status",
      action: "Action",
      detail: "Detail",
      actions: {
        LOCK_TASK: "Lock Table",
        UNLOCK_TASK: "Unlock Table",
        LOCK_VERSION: "Lock Dataset",
        UNLOCK_VERSION: "Unlock Dataset",
        CHANGE_DATA: "Change Data",
      },
      detail_info: {
        lock_task: "Lock【{{table}}({{user}})】",
        unlock_task: "Unlock【{{table}}({{user}})】",
        lock_version: "Lock Dataset",
        unlock_version: "Unlock Dataset",
        change_data: {
          delete: "Delete：Deleted {{count}} records in【{{table}}({{user}})】",
          add: "Add：{{count}} new records were added to【{{table}}({{user}})】",
          change: "Modified：Modified the data of【{{table}}({{user}})】",
          change_cell:
            "【{{field}}】is modified from “{{origin}}” to “{{new}}”",
        },
      },
      logs: {
        view: "View Logs",
        title: "Logs",
        main_log: "Main Log",
        node_logs: "Node Logs",
        name: "Log Name",
        id: "Log ID",
        status: "Status",
        started_at: "Started At",
        finished_at: "Finished At",
        duration: "Duration",
        log: "Log",
        get_info_fail: "Failed to get log information",
      },
    },
    push_config: {
      title: "Execution Config",
      workspace_id: "Flow Workspace ID",
      workspace_id_tip:
        "Go to Flow - Workspace Settings - Auth settings, copy the Workspace ID",
      flow_api_token_tip:
        "Go to Flow - Workspace Settings - Auth settings, copy a valid token",
      push_method: "Push Method",
      odp_api_token: "ODP API Token",
      app: "App Name",
      is_push_method_blank: "Please select push method first",
      is_blank:
        "Please fill in the Flow Workspace ID and Flow API Token first.",
      folder_is_blank:
        "Please fill in the Flow Workspace ID, App Name and Flow API Token first.",
      session: "Flow target folder",
      auto_lock: "Auto Lock",
      auto_solve: "Auto Solve",
      flow_api_token_invalid:
        "The Flow API Token is invalid. Please verify if it has expired.",
    },
    progress: {
      version_progress: "Version Progress",
      table: "Table",
      assignee: "Assignee",
      is_locked: "Is Locked?",
      title: "Progress",
    },
    actions: {
      push: "Push",
      execute: "Execute",
      lock: "Lock",
      unlock: "Unlock",
      add_version: "Add Version",
      update_version: "Update Version",
      data_validation: "Data validation",
      add_table: "Add Table",
      tasks: "Tasks",
      share: "Share Dataset",
      compare: "Compare",
      balance: "Balance",
      succeeded_notification: "Execution Succeeded",
      failed_notification: "Execution Failed",
      batch_operations: "Bulk allocation",
      batch_import: "Batch Import",
      delete_table: "Delete Table",
    },
    tips: {
      server_error: "Server error, please try again later",
      select_user: "Please select the operator",
      only_view: "Only View",
      can_manage: "Already have all permissions",
      confirm_copy: "Are you sure you want to copy the dataset?",
      confirm_copy_desc:
        "Only the current structure will be copied. Are you sure you want to copy?",
      confirm_delete: "Are you sure to delete the dataset?",
      confirm_delete_desc: `<p>Selected dataset: {{dataset_name}}</p>
        <p>Once deleted, it cannot be recovered, please proceed with caution.</p>`,
      need_lock_dataset: "The dataset needs to be locked first",
      need_push_config: "Please set push configuration first",
      table_is_locked:
        "The current table is locked. If you need to unlock it, please contact the admin or owner.",
      need_unlock: "If you need to operate, please unlock the dataset first",
      confirm_task_lock: "Confirm to save and lock current task data?",
      confirm_task_lock_desc: "Once locked, only admin can unlock it",
      table_drag_tip:
        "The order can be adjusted by dragging, and it will take effect immediately after the dragging is successful.",
      user_existed: "The current operator has been added",
      cannot_lock: "There are subtasks that are not locked, please review",
      flow_app_disabled: "The Flow app is not enabled",
      flow_app_not_set: "The Flow app is not set or the app is not enabled",
      api_token_not_set: "The API token is not set",
      greater_than_production:
        "The limit value is greater than the production plan",
      greater_than_demand: "The limit value is greater than the demand plan",
      balanced_production_demand_title: "Tip",
      balanced_production_demand_content:
        "Production plan and demand plan is not balanced, do you want auto balancing?",
      select_compare_version: "Select Compare Version",
      select_origin_version: "Please select the original version",
      select_new_version: "Please select the comparison version",
      select_diff_version: "Please select a different version for comparison",
      select_file: "Please select file",
      data_setup_mismatch: "Data table fields do not match",
      data_setup: "Data Setup",
      data_setup_tips:
        "When assigning tasks, you can select the basic data that needs to be assigned, and you can add filtering conditions to perform secondary filtering of the data.",
      setup_flow_app: "Please setup Flow app",
      setup_pipeline: "Please setup Pipeline App",
      no_data: "No data in this version, please check",
      data_validation_failed: "Data validation failed",
      missing_table:
        "No 【{{table}}】 table, please provider one and check table name",
      missing_field: "【{{field}}】 in {{row}} line is empty",
      invalid_reference:
        "{{reference_column}}【{{value}}】in {{row}} line of【{{reference_table}}】does not exist in【{{definition_table}}】table, please check",
      task_assigned: "Assigned",
      task_assigne_table_required: "Please select the table to be assigned",
      task_assigne_operator_required:
        "Please select the operator to be assigned",
      add_tasks_in_batches_success: "Batch assignment of tasks successful",
      add_tasks_in_batches_fail:
        "Batch assignment of tasks: {{success_count}} successful, {{fail_count}} failed",
      confirm_delete_version:
        "Are you sure you want to delete this dataset version?",
      confirm_delete_table:
        "Are you sure you want to delete this dataset table?",
      version_not_valid: "The version is not valid, please check the data",
    },
    sheet_error_tips: {
      title: "Error messages",
      jump_to: "Jump to",
      message: "Line {{row}}, {{error}}",
    },
  },
  table: {
    title: "Base Table",
    table: "Table",
    name: "Name",
    identifier: "Identifier",
    members: "Members",
    info: "Table Info",
    import: {
      upload_blank: "Please upload file",
      table_blank: "Please select a table",
      field_blank: "Please select a field",
      match_blank: "Please match the selected fields",
      upload: "upload file",
      upload_format: "Supports .csv, .xls, .xlsx formats",
      mult_table: "The file you uploaded has multiple tables, please select",
      target_field: "Target Field",
      source_field: "Source Field",
      title: "Import Data",
      match_field: "match Field",
      batch_import: "Batch Import Data",
      select_tables: "Select Tables",
      no_task_tips:
        "Please assign the task to the current user and is unlocked",
      import_and_save: "Import & Save",
      import_and_lock: "Import & Lock",
      import_failed: "Failed to {{action}} table {{table}}",
    },
    field: {
      title: "Fields",
      name: "Name",
      identifier: "Identifier",
      type: "Type",
      reference_table: "Reference",
      reference_field: "Reference Field",
      reference_field_display: "Reference Field Display",
      is_pk: "is Pk",
      is_unique: "is Unique",
      is_nullable: "is Nullable",
      is_filterable: "is Filterable",
      add_field: "Add Field",
      edit_field: "Edit Field",
      name_existed: "Field name already exists, please re-enter",
      name_format:
        "Field names only support Chinese, English and common characters",
      identifier_info:
        "The field identification name will be used as an identifier for storage in the database. It only supports lowercase letters, underscores, numbers and dollar signs ($), and must start with a lowercase letter",
      identifier_format:
        "Only lowercase letters, underscores, numbers, and dollar signs ($) are supported, and must start with a lowercase letter",
      identifier_existed: "The identifier already exists, please re-enter",
      text: "Text",
      number: "Number",
      date: "Date",
      boolean: "Boolean",
      reference: "Reference",
      rule: "Please enter the verification rule",
      min: "Min value",
      max: "Max value",
      only_unique: "Only display fields with unique values",
      readonly: "Read Only",
      can_not_delete_referenced:
        "This field is referenced and cannot be deleted",
      data_will_lost_after_delete: "Data will be lost after deletion",
    },
    actions: {
      info: "Table Info",
      data_validation: "Data validation",
      share: "Share Base Table",
      remove_member: "Remove Member",
      edit: "Edit Table",
      new: "New Table",
      sync: "Sync Data",
    },
    action_record: {
      title: "Records",
      action_record: "Execution Record",
      start_time: "Start Time",
      end_time: "End Time",
      operator: "Operator",
      status: "Status",
      action: "Action",
      detail: "Detail",
      actions: {
        CHANGE_DATA: "Change Data",
        SYNC_DATA: "Sync Data",
      },
      detail_info: {
        change_data: {
          delete: "Delete：Deleted {{count}} records",
          add: "Add：{{count}} new records were added",
          change_cell:
            "Modified：【{{field}}】is modified from “{{origin}}” to “{{new}}”",
        },
        sync_data: {
          sync: "【{{datetime}}】sync data",
        },
      },
    },
    tips: {
      select_user: "Please select the operator",
      only_view: "Only View",
      can_manage: "Already have all permissions",
      confirm_copy: "Are you sure you want to copy the table?",
      confirm_copy_desc:
        "Only the current table structure will be copied. Are you sure to copy?",
      confirm_delete: "Are you sure to delete the table?",
      confirm_delete_desc: `<p>Selected table: {{table_name}}</p>
        <p>Once deleted, it cannot be recovered, please proceed with caution.</p>`,
      delete_fail: "Unable to delete table, please try again later",
      delete_success: "Deleted successfully",
      is_referenced:
        "The current table field has been referenced by {{content}} and is not allowed to be deleted.",
      has_views:
        "The current table has been referenced by the dataset {{content}} and is not allowed to be deleted.",
      change_field_fail:
        "The table is not empty and the header cannot be modified.",
      name_existed: "Table name already exists, please re-enter it",
      fields_empty: "The fields is empty, please re-enter",
      no_permission: "No permission to modify the permissions of 「Owner」",
      name_format:
        "Table names only support Chinese, English and common characters",
      sync_data: "Synchronize data from outside",
      sync_data_help:
        "Synchronize data from outside use interface, Once selected, cannot be modified.",
      pk_must: "Please create a primary key",
      field_readonly: "Only administrators can modify",
      syncing: "Data is being synchronized, please wait",
      add_field: "Please add a field",
    },
  },
  workspace: {
    title: "Workspace Settings",
    name: "Name",
    role: "Role",
    member: "Member",
    api_key: "Key",
    token: "Token",
    admin: "Admin",
    owner: "Owner",
    email: "Email",
    account: "Account",
    username: "Username",
    created_at: "Created At",
    created_by_name: "Inviter",
    logined: {
      title: "Activated Status",
      active: "Active",
      inactive: "Inactive",
    },
    last_used: "Last Used At",
    upload_file_method: "Click or drag file to this area to upload",
    upload_file_format: "Supports .csv, .xls, .xlsx formats",
    import_rule: `<p>Import rule</p>
    <p>1.Smaller than {{size}}M;</p>
    <p>2.Cannot add more than {{num}} records rest records would be ignored;</p>
    </p>3.click to download employee template <a>employee_template.xlsx</a></p>`,
    import_result:
      "<success>{{success}}</success> records have been imported successfully，<failure>{{failure}}</failure> records have not been imported. <a>Download failure data</a>",
    actions: {
      add: "Add Workspace",
      invite_member: "Invite User",
      invite: "Invite",
      invite_button: "Invite({{num}})",
      batch_add: "Batch Add",
      update_member: "Update Member",
      delete_member: "Delete Member",
      reupload: "Reupload",
      continue_invite: "Continue inviting",
      edit: "Edit",
      quit: "Quit workspace",
      confirm_quit: "Confirm to quit",
      membership_management: "Membership",
      auth_management: "Auth",
    },
    tips: {
      workspace_not_found:
        "The current workspace does not exist, please refresh the page",
      no_workspace: "No workspace selected!",
      no_memeber: "No member selected!",
      member_is_existed: "The user is already in the workspace!",
      confirm_delete_member: "Are you sure you want to delete member {{name}}?",
      confirm_api_key: "Are you sure you want to delete Token {{name}}?",
      cannot_quit: "Owner cannot quit",
      save_key:
        "Please store it carefully and ensure it is not disclosed. Once closed, you will not be able to retrieve it from the system again",
      you_key: "Your token is：",
      copy_token: "Copy token",
      change_fail: "Change Workspace Failed",
      invitation_length: "Cannot add more than {{num}} records once",
      no_user_add: "No user added",
      no_upload_file: "No file to upload",
      is_not_excel: "{{name}} is not an excel file",
      max_excel_size: "Excel size cannot be great than {{size}}M",
      max_excel_records: "Excel line number can't be great than {{num}}",
      invalid_excel: "There must be account and role column in excel",
      invalid_email: "This is not a valid email, please reinput",
      confirm_quit_content: `<p>Selected workspace: <b>{{workspace}}</b></p>
      <p>If you quit, you cannot join this workspace again, are you sure to continue</p>`,
      existed: "existed",
      copy_workspace_id: "Copy workspace id {{status}}",
      only_admin: "Only admin user can create token",
    },
  },
  notification: {
    popover: {
      dataset: "Dataset",
      workspace: "Workspace",
      platform: "Platform",
      read_all: "Read all",
    },
    dataset: "Dataset",
    workspace: "Workspace",
    system: "System",
    all: "All",
    read: "Read",
    unread: "Unread",
  },
  common: {
    version: "Version",
    all: "All",
    workspace: "Workspace",
    success: "Successed",
    fail: "Failed",
    logout: "Logout",
    actions: "Actions",
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    update: "Update",
    create: "Create",
    info: "Info",
    copy: "Copy",
    close: "Close",
    ok: "Ok",
    add_with_info: "Add {{title}}",
    manage_with_info: "Manage {{title}}",
    status: "Status",
    enabled: "Enabled",
    created_at: "Created At",
    updated_at: "Updated At",
    description: "Description",
    cancel: "Cancel",
    save: "Save",
    modify: "Update",
    required: "{{title}} is blank!",
    invalid: "{{title}} is invalid!",
    result_status: "{{title}} is {{status}}!",
    name: "Name",
    owner: "Owner",
    char_len_limit: "The {{title}} cannot exceed {{count}} characters at most!",
    try_again: "Please try again later",
    import: "Import",
    export: "Export",
    no_permission: "No Permission",
    back: "Back",
    share: "Share",
    change: "Changed",
    first: "First",
    previous: "Previous",
    next: "Next",
    last: "Last",
    check_all: "Check All",
    yes: "Yes",
    no: "No",
    form_mode: "Form",
    step: {
      previous: "Previous",
      next: "Next",
    },
    finish: "Finish",
    table: "Table",
    source: "Data Source",
    data: "Data",
    reset: "Reset",
    search: "Search",
    not_configured: "Not Configured",
    total: "Total {{count}} items",
    old: "Old",
    remove: "Remove",
    server_error_tips:
      "Please try again later or contact customer service staff",
    refresh: "Refresh",
    upsert: "Upsert",
  },
  flow_instance: {
    get_instances_title: "Get Flowopt Instances",
    select_instances_title: "Select Instances",
    instance_id: "Instance ID",
    instance_name: "Instance Name",
    instance_description: "Description",
  },
  chart_view: {
    title: "Chart",
    name: "Chart Name",
    select_dataframe: "Select Table",
    select_chart_type: "Select Chart Type",
    count_records_label: "Number of records",
    count_records: "Count records",
    count_a_field: "Count a field",
    select_filed: "Please select a field",
    sum: "Sum",
    min: "Min",
    max: "Max",
    average: "Average",
    empty_stacked_by: "[None]",
    sort_by_x: "Sort by X axis",
    sort_by_y: "Sort by Y axis",
    sort_rule_ascending: "Ascending",
    sort_rule_descending: "Descending",
    more_settings: "More settings",
    color_picker: "Select theme color",
    multi_color_theme: "Multi-color theme",
    monochrome_gradient_theme: "Monochrome gradient theme",
    theme_color_1: "Theme color 1",
    theme_color_2: "Theme color 2",
    theme_color_3: "Theme color 3",
    theme_color_4: "Theme color 4",
    theme_color_5: "Theme color 5",
    theme_color_6: "Theme color 6",
    theme_color_7: "Theme color 7",
    theme_color_8: "Theme color 8",
    chart_type_enum: {
      heatmap: "Heatmap",
      map: "Map",
      location_relationship: "Location Relationship Chart",
      column_chart: "Column Chart",
      stacked_column_chart: "Stacked Column Chart",
      percentage_column_chart: "Percentage Column Chart",
      bar_chart: "Bar Chart",
      stacked_bar_chart: "Stacked Bar Chart",
      percentage_bar_chart: "Percentage Bar Chart",
      pie_chart: "Pie Chart",
      donut_chart: "Donut Chart",
      line_chart: "Line chart",
      stacked_line_chart: "Stacked Line Chart",
      scatter_plot: "Scatter Plot",
      funnel_chart: "Funnel Chart",
    },
    config: {
      pie_chart: {
        value: "Values",
        dimension: "Dimension",
        text_size: "Text Size",
      },
      donut_chart: {
        value: "Values",
        dimension: "Dimension",
        text_size: "Text Size",
      },
      bar_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Group by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      stacked_bar_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      percentage_bar_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "100% Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      line_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Group by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      stacked_line_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      column_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Group by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      stacked_column_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      percentage_column_chart: {
        dimension: "Select X axis",
        value: "Select Y axis",
        stacked_by: "100% Stacked by",
        sort_by: "Select an axis to sort by",
        sort_rule: "Select sort rule",
        text_size: "Text Size",
      },
      gaode_heatmap: {
        opacity: "Overall transparency",
        show_tip: "Whether to display label",
        unit: "Unit of heat",
        radius: "Convergence radius",
        colors: "Color scheme",
        max: "Maximum value of heat value",
        min: "Minimum value of heat value",
        difference: "Whether to disable color transition",
        is3d: "Whether to enable 3D effect",
        px: "px",
        meter: "meter",
        latitude: "Latitude Field",
        longitude: "Longitude Field",
        value: "Value Field",
      },
      location_relationship: {
        opacity: "Overall transparency",
        show_tip: "Whether to display label",
        main_latitude: "Main Latitude Field",
        main_longitude: "Main Longitude Field",
        sub_latitude: "Sub Latitude Field",
        sub_longitude: "Sub Longitude Field",
        main_name: "Main Name Field",
        sub_name: "Sub Name Field",
        main_size: "Main Size",
        sub_size: "Sub Size",
        line_width: "Line Width",
      },
      map_type: {
        title: "Please select the map you want to use",
        default: "Default",
        gaode: "Gaode Map",
        baidu: "Baidu Map",
        input_key: "Fill in the application Key",
      },
    },
    action: {
      add: "Add Chart",
    },
    tip: {
      create: "Chart View creation {{status}}!",
      update: "Chart View update {{status}}!",
      delete: "Chart View deletion {{status}}!",
      table_blank: "Please select a table",
    },
    confirm: {
      delete: "Are you sure you want to delete this chart?",
    },
  },
  system_manage: {
    pipeline_manage: {
      pipeline: "Pipeline",
      manifest: "Manifest",
      active: "Active",
      active_true: "Active",
      active_false: "Inactive",
      type: "Type",
      public: "Public",
      private: "Private",
      private_workspaces: "Workspaces",
      confirm_delete: "Are you sure to delete {{pipeline_name}}?",
      image: "Image",
      tip: {
        add: "Add pipeline {{status}}",
        update: "Update pipeline {{status}}",
        id_exist: "Id exists",
        name_exist: "Name exists",
        invalid_id: "Id not found",
        cannot_edit_id: "Cannot edit id",
        cannot_edit_name: "Cannot edit name",
      },
    },
    base_table_manage: {
      base_table: "Base Table",
      confirm_delete: "Are you sure to delete {{table_name}}?",
    },
  },
  dashboard: {
    title: "Dashboard",
    add_chart: "Add chart",
    full_screen: "Full screen",
    remove: "Remove from dashboard",
    origin: "Origin:「{{dataset_name}}」",
    add: "Add",
    tips: {
      remove:
        "Chart would not be presented in dashboard once removed, are you sure to remove?",
    },
  },
};

export default Translation;
