import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SheetTable } from "../../types/Table";
import { Task } from "../../types/Dataset";
import { Table, Tooltip, Modal, Radio, Button } from "antd";
import type { RadioChangeEvent } from "antd";
import type { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import IconReloadTime from "../../common/images/icons/reload-time";

type TableTask = {
  id: number;
  table: string;
  assignee: string;
  assignee_name: string;
  is_locked: boolean;
};

const DatasetVersionProgress = (props: { versionTables: SheetTable[] }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState<{ is_locked?: boolean }>({});

  const onChange = (e: RadioChangeEvent) => {
    setSearch({ is_locked: e.target.value });
  };

  const currentVersionTasksCount = props?.versionTables?.reduce(
    (ret: any, table: SheetTable) => {
      table?.tasks?.forEach((task: Task) => {
        if (task.is_locked) {
          ret[0]++;
        }
        ret[1]++;
      });
      return ret;
    },
    [0, 0]
  );

  const TasksData: TableTask[] = props?.versionTables
    ?.reduce((ret: any, table: SheetTable) => {
      table?.tasks?.forEach((task: Task) => {
        ret.push({
          id: task.id || 0,
          table: table.name || "",
          assignee: task.assignee_user?.username || "",
          assignee_name: task.assignee_user?.name || "",
          is_locked: task.is_locked || false,
        });
      });
      return ret;
    }, [])
    .filter((task: TableTask) =>
      search.is_locked === undefined
        ? true
        : task.is_locked === search.is_locked
    );

  const closeModal = () => {
    setShow(false);
  };

  if (currentVersionTasksCount[1] === 0) {
    return <></>;
  }

  return (
    <>
      <Modal
        centered
        width={550}
        title={t("dataset.progress.version_progress")}
        open={!!show}
        onCancel={closeModal}
        footer={null}
      >
        <div style={{ marginTop: "2em", marginBottom: "1.5em" }}>
          <Radio.Group onChange={onChange} value={search?.is_locked}>
            <Radio value={undefined}>{t("common.all")}</Radio>
            <Radio value={false}>{t("dataset.task_status.unlocked")}</Radio>
            <Radio value={true}>{t("dataset.task_status.locked")}</Radio>
          </Radio.Group>
        </div>
        <Table
          size="small"
          rowKey={(r) => r?.id || ""}
          dataSource={TasksData}
          scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
          columns={
            [
              {
                key: "table",
                title: t("dataset.progress.table"),
                render: (r) => r.table,
              },
              {
                key: "assignee",
                title: t("dataset.progress.assignee"),
                render: (r) => (
                  <Tooltip title={r.assignee}>
                    {viewUtils.prettifyUsername(r.assignee_name)}
                  </Tooltip>
                ),
              },
              {
                key: "is_locked",
                fixed: "right",
                title: t("dataset.progress.is_locked"),
                render: (r) =>
                  r.is_locked
                    ? t("dataset.task_status.locked")
                    : t("dataset.task_status.unlocked"),
              },
            ] as ColumnsType<TableTask>
          }
          pagination={{
            size: "default",
            hideOnSinglePage: true,
            pageSize: 10,
            showSizeChanger: false,
            showTotal: (total) =>
              t("common.total", { count: TasksData?.length || 0 }),
            style: {
              textAlign: "right",
              justifyContent: "flex-end",
              marginTop: "1em",
            },
          }}
        />
      </Modal>
      <div className="action-item" onClick={() => setShow(true)}>
        <Button
          icon={
            <IconReloadTime
              style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
            />
          }
        >
          <span>{t("dataset.progress.title")}</span>
          <span
            style={{
              fontSize: "0.9em",
              marginTop: "-0.3em",
              marginBottom: "0.2em",
            }}
          >
            ({`${currentVersionTasksCount[0]}/${currentVersionTasksCount[1]}`})
          </span>
        </Button>
      </div>
    </>
  );
};

export default DatasetVersionProgress;
