import React, { useEffect, useState } from "react";
import NavHyperLink from "../../components/NavHyperLink";
import {
  Button,
  Table,
  Modal,
  message,
  Form,
  Input,
  Typography,
  Radio,
  Select,
  Checkbox,
  Image,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import { useTranslation } from "react-i18next";
import { Dataset } from "../../types/Dataset";
import {
  getSystemDatasetListApi,
  updateSystemDatasetApi,
} from "../../api/DatasetApi";
import HeaderSearchIcon from "../../common/images/icons/header-search-icon.png";

const DatasetManage = () => {
  const { t } = useTranslation();
  const [datasets, setDatasets] = useState<{ data: Dataset[]; total: number }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    query: "",
  });

  useEffect(() => {
    getSystemDatasetListApi({
      page: filter.page,
      page_size: filter.page_size,
      query: filter.query,
    }).then((res) => {
      if (res.success) {
        setDatasets({
          data: res.data,
          total: res.pagination?.count || 0,
        });
      }
    });
  }, [filter]);

  const handleConfiged = () => {
    setFilter({ ...filter });
  };

  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFilter((data) => ({ ...data, query: e.target.value, page: 1 }));

  const columns: ColumnsType<Dataset> = [
    {
      title: "ID",
      key: "id",
      width: 50,
      render: (r) => r.id,
    },
    {
      title: "数据集名称",
      key: "name",
      width: 200,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              title: r?.workspace?.name,
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          style={{ marginBottom: 0 }}
        >
          {r?.name}
        </Typography.Paragraph>
      ),
    },
    {
      title: "描述",
      key: "description",
      width: 200,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              title: r?.workspace?.name,
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          style={{ marginBottom: 0 }}
        >
          {r?.description || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "所属空间",
      key: "workspace",
      width: 200,
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              title: r?.workspace?.name,
              placement: "bottomLeft",
              overlayClassName: "description_tooltip",
            },
          }}
          style={{ marginBottom: 0 }}
        >
          {r?.workspace?.name || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "创建时间",
      key: "created_at",
      width: 200,
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: "更新时间",
      key: "update_at",
      width: 200,
      render: (r) => viewUtils.utcToLocal(r.updated_at),
    },
    {
      title: "创建人",
      key: "owner",
      width: 200,
      render: (r) => r?.owner_user?.name,
    },
    {
      key: "actions",
      title: "操作",
      fixed: "right",
      render: (r: Dataset) => (
        <div style={{ display: "flex" }}>
          <NotificationConfigButton dataset={r} onConfiged={handleConfiged} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavHyperLink items={[{ title: "数据集" }]} />
        <div>
          <Input
            style={{ width: "50vw", maxWidth: "30em" }}
            value={filter?.query}
            placeholder={`${t("dataset.name")}、${t("common.description")}、${t(
              "common.owner"
            )}`}
            onChange={handleSearch}
            suffix={
              <Image
                src={HeaderSearchIcon}
                preview={false}
                style={{ width: 15, height: 15 }}
              />
            }
          />
        </div>
      </div>
      <div style={{ marginTop: "1.5em" }}>
        <Table
          className="common-table"
          rowKey={(r) => r.id || ""}
          scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
          columns={columns}
          dataSource={datasets?.data || []}
          pagination={{
            showQuickJumper: true,
            showTotal: (total) => `共 ${total} 条`,
            current: filter.page,
            pageSize: filter.page_size,
            total: datasets?.total ?? 0,
            onChange: handlePageChange,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            style: { margin: "1em" },
          }}
        />
      </div>
    </>
  );
};

const NotificationConfigButton = (props: {
  dataset: Dataset;
  onConfiged: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      return;
    }
    form.setFieldsValue({
      enabled: props.dataset?.notification_config?.enabled || false,
      after_version_data_save: {
        teams:
          props.dataset?.notification_config?.after_version_data_save?.teams ||
          false,
        yili:
          props.dataset?.notification_config?.after_version_data_save?.yili ||
          [],
      },
      after_version_execute_start: {
        teams:
          props.dataset?.notification_config?.after_version_execute_start
            ?.teams || false,
        yili:
          props.dataset?.notification_config?.after_version_execute_start
            ?.yili || [],
      },
      after_version_execute_end: {
        teams:
          props.dataset?.notification_config?.after_version_execute_end
            ?.teams || false,
        yili:
          props.dataset?.notification_config?.after_version_execute_end?.yili ||
          [],
      },
    });
  }, [open]);

  const handleOk = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    if (!props.dataset.id) {
      return;
    }
    const notification_config = {
      ...props.dataset.notification_config,
      ...form.getFieldsValue(),
    };
    const res = await updateSystemDatasetApi({
      datasetId: props.dataset.id,
      data: { notification_config: notification_config },
    });
    if (res.success) {
      message.success("通知配置成功");
      setOpen(false);
      props.onConfiged();
    } else {
      message.error("通知配置失败");
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title={"通知配置"}
        okText={t("common.save")}
        cancelText={t("common.cancel")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
      >
        <Form name="user form" form={form} autoComplete="off">
          <Form.Item label={"是否开启通知"} name="enabled">
            <Radio.Group>
              <Radio value={true}>开启</Radio>
              <Radio value={false}>关闭</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.enabled !== currentValues.enabled
            }
          >
            {() => {
              return form.getFieldValue("enabled") ? (
                <>
                  <Typography.Text>
                    场景一：数据集版本更新数据后
                  </Typography.Text>
                  <Form.Item
                    label={"Convect Teams"}
                    name={["after_version_data_save", "teams"]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group>
                      <Radio value={true}>开启</Radio>
                      <Radio value={false}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={"伊利消息通知"}
                    name={["after_version_data_save", "yili"]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      open={false}
                    />
                  </Form.Item>
                  <Typography.Text>
                    场景二：数据集版本开始执行推送
                  </Typography.Text>
                  <Form.Item
                    label={"Convect Teams"}
                    name={["after_version_execute_start", "teams"]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group>
                      <Radio value={true}>开启</Radio>
                      <Radio value={false}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={"伊利消息通知"}
                    name={["after_version_execute_start", "yili"]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      open={false}
                    />
                  </Form.Item>
                  <Typography.Text>
                    场景三：数据集版本推送运行结束后
                  </Typography.Text>
                  <Form.Item
                    label={"Convect Teams"}
                    name={["after_version_execute_end", "teams"]}
                    style={{ marginBottom: 0 }}
                  >
                    <Radio.Group>
                      <Radio value={true}>开启</Radio>
                      <Radio value={false}>关闭</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={"伊利消息通知"}
                    name={["after_version_execute_end", "yili"]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      open={false}
                    />
                  </Form.Item>
                </>
              ) : (
                <></>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
      <Button
        size="small"
        type="link"
        onClick={() => {
          setOpen(true);
        }}
      >
        通知配置
      </Button>
    </>
  );
};

export default DatasetManage;
