import React from "react";
import { Route, Routes } from "react-router-dom";
import SystemManageLayout from "../../components/layouts/SystemManageLayout";

import Dashboard from "./Dashboard";
import UserManagePage from "./UserManage";
import DatasetTemplatePage from "./DatasetTemplate";
import DatasetTemplateInfoPage from "./DatasetTemplate/DatasetTemplateInfoPage";
import PipelineManagePage from "./PipelineManagePage";
import BaseTableManage from "./BaseTableManage";
import DatasetManage from "./DatasetManage";

const SystemManage = () => {
  return (
    <SystemManageLayout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="base_tables" element={<BaseTableManage />} />
        <Route path="datasets" element={<DatasetManage />} />
        <Route path="templates/dataset" element={<DatasetTemplatePage />} />
        <Route
          path="templates/dataset/create"
          element={<DatasetTemplateInfoPage />}
        />
        <Route
          path="templates/dataset/create/:dataset_templdate_id"
          element={<DatasetTemplateInfoPage />}
        />
        <Route
          path="templates/dataset/edit/:dataset_templdate_id"
          element={<DatasetTemplateInfoPage />}
        />

        <Route path="users" element={<UserManagePage />} />
        <Route path="pipelines" element={<PipelineManagePage />} />
      </Routes>
    </SystemManageLayout>
  );
};

export default SystemManage;
