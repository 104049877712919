import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  FundProjectionScreenOutlined,
  ProjectOutlined,
  UserOutlined,
  ControlOutlined,
  TableOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

const NavMenu = () => {
  const location = useLocation();

  const MenuList = [
    {
      label: <NavLink to={`/system_manage`}>仪表盘</NavLink>,
      key: "dashboard",
      icon: <FundProjectionScreenOutlined />,
    },
    {
      label: <NavLink to={`/system_manage/base_tables`}>基础数据</NavLink>,
      key: "tables",
      icon: <TableOutlined />,
      selected: location.pathname.startsWith("/system_manage/tables"),
    },
    {
      label: <NavLink to={`/system_manage/datasets`}>数据集</NavLink>,
      key: "datasets",
      icon: <ContainerOutlined />,
      selected: location.pathname.startsWith("/system_manage/datasets"),
    },
    {
      label: "模版管理",
      key: "templates",
      icon: <ProjectOutlined />,
      children: [
        {
          label: (
            <NavLink to={`/system_manage/templates/dataset`}>
              数据集模版
            </NavLink>
          ),
          key: "templates-dataset",
          selected: location.pathname.includes("/templates/dataset"),
        },
      ],
    },
    {
      label: <NavLink to={`/system_manage/users`}>用户管理</NavLink>,
      key: "users",
      icon: <UserOutlined />,
      selected: location.pathname.startsWith("/system_manage/users"),
    },
    {
      label: <NavLink to={`/system_manage/pipelines`}>执行流程管理</NavLink>,
      key: "pipelines",
      icon: <ControlOutlined />,
      selected: location.pathname.startsWith("/system_manage/pipelines"),
    },
  ];

  const [defaultActiveKey, defaultOpenKeys] = MenuList.reduce(
    (ret: any, item: any) => {
      let active: any = item;
      if (!!item.children) {
        active = item.children.find(
          (subitem: any) => !!subitem.selected && subitem.label
        );
      }
      if (!!active && !!active.selected) {
        ret = [active.key, item.children ? [item.key] : []];
      }
      return ret;
    },
    ["dashboard", []]
  );

  return (
    <Menu
      theme="light"
      mode="inline"
      className="system-sidebar-menu"
      items={MenuList}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultActiveKey}
    ></Menu>
  );
};

export default NavMenu;
