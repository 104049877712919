import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Empty,
  message,
  Modal,
  notification,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { SheetTable } from "../../types/Table";
import { DatasetVersion, Task } from "../../types/Dataset";

import {
  exportDatasetVersionApi,
  getDatasetVersionTableDataApi,
  getDatasetVersionTableViewDataApi,
  exportDatasetVersionTableViewDataApi,
  updateDatasetVersionTaskApi,
  updateDatasetVersionTaskDataApi,
  unlockDatasetVersionApi,
  createDatasetVersionTaskApi,
  deleteDatasetVersionTaskApi,
  lockDatasetVersionApi,
  executeApi,
} from "../../api/DatasetApi";
import viewUtils from "../../utils/viewUtils";
import GlobalLoader from "../../components/GlobalLoading";
import { checkBalance } from "../../utils/SheetBalanceUtils";
import { calCompareTableDataStatistics } from "../../utils/SheetCompareUtils";
import { validateSchema } from "../../utils/FlowValidationUtils";

import IconExport from "../../common/images/icons/export";
import IconImport from "../../common/images/icons/import";
import IconLock from "../../common/images/icons/lock";
import IconRocket from "../../common/images/icons/rocket";
import IconSave from "../../common/images/icons/save";
import IconUnlock from "../../common/images/icons/unlock";
import { CaretDownOutlined, ProfileOutlined } from "@ant-design/icons";

import TablesSheet from "../../components/TablesSheet";
import useDatasetStore from "./useDatasetStore";
import DatasetVersionAction from "./DatasetVersionAction";
import DatasetShare from "./DatasetShare";
import DatasetChart from "./DatasetChart";
import DatasetActionHistory from "./DatasetActionHistory";
import DatasetActionConfig from "./DatasetActionConfig";
import DatasetVersionProgress from "./DatasetVersionProgress";
import DatasetVersionCompare from "./DatasetVersionCompare";
import DatasetVersionRunStatus from "./DatasetVersionRunStatus";
import DatasetOperators from "./DatasetOperators";
import TablesSheetForm from "../../components/TablesSheetForm";
import DatasetBatchAddTaskModal from "./DatasetBatchAddTaskModal";
import TableImportBatchModal from "../../components/TableImportBatchModal";
import TableImportModal from "../../components/TableImportModal";
import TableSheetErrorTips from "../../components/TableSheetErrorTips";
import { validateEmptyFields } from "../../utils/TableDataValidationUtils";
import { checkFlowApiTokenApi } from "../../api/IntegrationsApis";

const DatasetDataPage = () => {
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    permission,
    dataset_id,
    currentDataset,
    currentVersion,
    versionList,
    versionTables,
    updateVersion,
    deleteVersion,
    updateDataset,
    changeCurrentVersion,
    enableUseForm,
    shouldUseForm,
    changeTable,
    changeTask,
    changeCurrentTasks,
    updateCurrentTaskStatus,
    updateCurrentVersionStatus,
    setVersionTables,
    refreshVersionSheetFormData,
    resetCurrentVersion,
  } = useDatasetStore();
  const [initSheetData, setInitSheetData] = useState<
    SheetTable[] | undefined
  >();
  const [isRunning, setIsRunning] = useState(false);
  const currentTable = versionTables?.find((table) => !!table.active);
  const currentTask = currentDataset?.can_visit
    ? currentTable?.current_task
    : (currentTable?.tasks || [])[0];

  const datasetVersionRunStatusRef: any = useRef();
  const tableImportModalRef: any = useRef();
  const tableImportBatchModalRef: any = useRef();
  const sheetRef: any = useRef();
  const sheetFormRef: any = useRef();
  const sheetDataRef: any = useRef(null);
  const batchAddTaskModalRef: any = useRef();
  const datasetTableErrorTipsRef: any = useRef();

  useEffect(() => {
    if (!!versionTables) {
      if (sheetDataRef.current === null) {
        sheetDataRef.current = {};
        setInitSheetData(versionTables);
        //init table data
        for (let i = 0, len = versionTables.length; i < len; i++) {
          sheetDataRef.current[`${versionTables[i].identifier}`] = null;
          resetTableData(versionTables[i]);
        }
      }
    }
    // eslint-disable-next-line
  }, [versionTables]);

  const handleChangeCurrentVersion = (id: number) => {
    sheetDataRef.current = null;
    sheetRef.current && sheetRef.current.resetInitStatus();
    setInitSheetData(undefined);
    changeCurrentVersion(id);
  };

  const handleUpdateVersion = (
    version: DatasetVersion,
    resetTables?: SheetTable[]
  ) => {
    //add version
    if (!versionList?.find((v) => v.id === version.id)) {
      sheetDataRef.current = null;
      sheetRef.current && sheetRef.current.resetInitStatus();
      setInitSheetData(undefined);
    }
    //update version
    if (version.id === currentVersion?.id) {
      const tables =
        versionTables?.filter(
          (table: SheetTable) => table.table_type === "PRIMARY"
        ) || [];
      for (let i = 0, len = tables.length; i < len; i++) {
        resetTableData(tables[i]);
      }
      if (!!resetTables) {
        for (let i = 0, len = resetTables.length; i < len; i++) {
          resetTableData(resetTables[i], true, true);
        }
      }
    }
    updateVersion(version);
  };

  const handleDeleteVersion = (version: DatasetVersion) => {
    if (version.id === currentVersion?.id) {
      sheetDataRef.current = null;
      sheetRef.current && sheetRef.current.resetInitStatus();
      setInitSheetData(undefined);
    }
    deleteVersion(version);
  };

  const getCurrentTableData = (task_id?: any, tableIndex?: any) => {
    const table = tableIndex
      ? versionTables?.find(
          (table: SheetTable) => table.identifier === tableIndex
        )
      : versionTables?.find((table: SheetTable) => !!table.active);
    if (!!table) {
      if (task_id) {
        if (task_id !== "all") {
          return (
            (sheetDataRef.current || {})[`${table.identifier}`]?.tasks?.find(
              (task: Task) => task.id === task_id
            )?.data || []
          );
        } else {
          return (
            (sheetDataRef.current || {})[`${table.identifier}`]?.data || []
          );
        }
      } else {
        if (!!table.current_task) {
          return (
            (sheetDataRef.current || {})[`${table.identifier}`]?.tasks?.find(
              (task: Task) => task.id === table?.current_task?.id
            )?.data || []
          );
        } else {
          return (
            (sheetDataRef.current || {})[`${table.identifier}`]?.data || []
          );
        }
      }
    } else {
      return null;
    }
  };

  const resetCurrentTable = (task_id?: any) => {
    const tableData = getCurrentTableData(task_id);
    sheetRef?.current &&
      sheetRef.current.resetCurrentTable(
        tableData,
        task_id ? task_id !== "all" : !!currentTask?.id,
        true
      );
  };

  const resetTableData = (
    table: SheetTable,
    refreshSheet = true,
    resetData = false
  ) => {
    if (table.table_type === "PRIMARY") {
      getDatasetVersionTableViewDataApi({
        versionId: Number(currentVersion?.id),
        tableViewId: Number(table.id),
      }).then((res) => {
        if (res.success && sheetDataRef.current !== null) {
          sheetDataRef.current[`${table.identifier}`] = {
            data: res.data?.data || [],
          };
          if (refreshSheet) {
            sheetRef.current &&
              sheetRef.current.sheetDataLoaded(
                {
                  ...table,
                  data: res.data?.data,
                },
                true
              );
          }
        }
      });
    }
    if (table.table_type === "COMMON") {
      getDatasetVersionTableDataApi({
        versionId: Number(currentVersion?.id),
        tableId: Number(table.id),
      }).then((res) => {
        if (res.success && sheetDataRef.current !== null) {
          sheetDataRef.current[`${table.identifier}`] = {
            fields: res.data?.meta?.fields || [],
            data: res.data?.data || [],
            tasks: res.data?.tasks || [],
          };
          if (refreshSheet) {
            sheetRef.current &&
              sheetRef.current.sheetDataLoaded(
                {
                  ...table,
                  meta: res.data?.meta,
                  data: !!table.current_task
                    ? res.data?.tasks?.find(
                        (task: Task) => task.id === table.current_task?.id
                      )?.data || res.data?.data
                    : res.data?.data,
                },
                true
              );
          }
          if (resetData) {
            const tasks = res.data?.tasks || [];
            let current_task = tasks[0] || undefined;
            if (!!table.current_task) {
              current_task =
                tasks.find(
                  (t: Task) =>
                    t.id === table.current_task?.id ||
                    t.assignee === table.current_task?.assignee
                ) || undefined;
            }
            setVersionTables((data) =>
              data?.map((t: SheetTable) =>
                t.identifier === table.identifier
                  ? {
                      ...t,
                      tasks: tasks,
                      current_task: current_task,
                    }
                  : t
              )
            );
          }
        }
      });
    }
  };

  const resetVersionTablesData = (resetVersion?: boolean) => {
    const tables =
      versionTables?.filter(
        (table: SheetTable) => table.table_type === "COMMON"
      ) || [];
    for (let i = 0, len = tables.length; i < len; i++) {
      resetTableData(tables[i], true, true);
    }
    if (!!resetVersion) {
      resetCurrentVersion();
    }
  };

  const handleChangeTable = (table: SheetTable, needInit?: boolean) => {
    datasetTableErrorTipsRef?.current &&
      datasetTableErrorTipsRef.current.hide();
    changeTable(table);
    if (!!needInit) {
      const tableData = getCurrentTableData(null, table.identifier);
      sheetRef.current &&
        sheetRef.current.sheetDataLoaded(
          {
            ...table,
            data: tableData,
          },
          true
        );
    }
  };

  const handleChangeTask = (id: any) => {
    if (
      !!currentTable?.identifier &&
      !!(sheetDataRef.current || {})[`${currentTable?.identifier}`]
    ) {
      resetCurrentTable(id);
      changeTask(id);
    }
  };

  const handleCurrentTableTasksUpdateCallback = (
    action: String,
    task: Task,
    table_id?: number
  ) => {
    const table = changeCurrentTasks(action, task, table_id);
    !!table && resetTableData(table);
  };

  const handleUnLockDataset = () => {
    unlockDatasetVersionApi({
      versionId: Number(currentVersion?.id),
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("dataset.actions.unlock"),
            status: t("common.success"),
          })
        );
        updateCurrentVersionStatus(false);
      } else {
        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("dataset.actions.unlock"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const handleUpdateBalanceResult = async (
    tasks: Task[],
    originTable?: SheetTable
  ) => {
    if (!originTable) {
      return false;
    }
    if (tasks.length === 1 && !tasks[0]?.id) {
      let task = originTable.tasks?.find(
        (task: Task) => task.assignee === currentDataset?.owner
      );
      if (!task) {
        const taskRet = await createDatasetVersionTaskApi({
          assignee: currentDataset?.owner,
          table_id: Number(originTable?.id),
          version_id: Number(currentVersion?.id),
          is_locked: true,
        });
        if (!taskRet.success) {
          return false;
        }
        task = taskRet.data;
      }
      tasks[0].id = task.id;
    }
    const UpdateTasks = tasks.map((task: Task) => task.id);
    const deleteTasks = (originTable?.tasks || []).filter(
      (task: Task) => !UpdateTasks.includes(task.id)
    );
    for (let i in tasks) {
      const task = tasks[i];
      const originTask = originTable?.tasks?.find(
        (t: Task) => t.id === task.id
      );
      const compareDataRet = calCompareTableDataStatistics(
        {
          id: originTable?.id,
          name: originTable?.name,
          meta: originTable?.meta,
          data:
            (sheetDataRef.current || {})[
              originTable?.identifier || ""
            ]?.tasks?.find((t: Task) => t.id === originTask?.id)?.data || [],
        },
        { data: task.data || [] }
      );
      const res = await updateDatasetVersionTaskDataApi({
        taskId: Number(task?.id),
        data: {
          data: task.data || [],
          ...(compareDataRet.delete_count > 0 ||
          compareDataRet.add_count > 0 ||
          compareDataRet.change_data.length > 0
            ? {
                log: compareDataRet,
              }
            : {}),
        },
      });
      if (!res.success) {
        return false;
      }
    }
    for (let i in deleteTasks) {
      const task = deleteTasks[i];
      const res = await deleteDatasetVersionTaskApi({
        taskId: Number(task?.id),
      });
      if (!res.success) {
        return false;
      }
    }
    !!originTable && resetTableData(originTable, true, true);
    return true;
  };

  const handleLockDatasetWithBalanceResetOriginData = async (result: any) => {
    if (!!result?.table && result?.table !== result?.origin_table) {
      GlobalLoader.show();
      const OriginTable = versionTables?.find(
        (table) => table.identifier === result.table
      );
      const res = await handleUpdateBalanceResult(
        result?.origin_data || [],
        OriginTable
      );
      if (!res) {
        GlobalLoader.hide();
        message.error(
          t("common.result_status", {
            title: t("dataset.actions.balance"),
            status: t("common.fail"),
          })
        );
        return;
      }
    }
    lockVersion();
  };

  const handleLockDataset = async () => {
    const versionData =
      versionTables?.map((table: SheetTable) => ({
        ...table,
        tasks: table.tasks?.map((t: Task) => ({
          ...t,
          data: (sheetDataRef.current || {})[
            `${table.identifier}`
          ]?.tasks?.find((a: Task) => a.id === t.id)?.data,
        })),
        data: (sheetDataRef.current || {})[`${table.identifier}`]?.data || [],
      })) || [];

    const result: any = checkBalance(versionData);
    if (result?.status) {
      handleLockDatasetWithBalanceResetOriginData(result);
    } else {
      if (!result?.table) {
        message.error(
          t("common.result_status", {
            title: t("dataset.actions.balance"),
            status: t("common.fail"),
          })
        );
        return;
      }
      Modal.confirm({
        title: t("dataset.tips.balanced_production_demand_title"),
        content: t("dataset.tips.balanced_production_demand_content"),
        centered: true,
        onOk: async () => {
          GlobalLoader.show();
          const OriginTable = versionData.find(
            (table) => table.identifier === result.table
          );
          const res = await handleUpdateBalanceResult(
            result?.data || [],
            OriginTable
          );
          if (!res) {
            message.error(
              t("common.result_status", {
                title: t("dataset.actions.balance"),
                status: t("common.fail"),
              })
            );
          } else {
            if (result.table === result.origin_table) {
              sheetRef.current.addTable(
                {
                  ...OriginTable,
                  current_task: undefined,
                  editable: false,
                  table_type: "TEMP",
                  identifier: `${OriginTable?.identifier}-copy-temp`,
                  name: `${OriginTable?.name}(${t("common.old")})`,
                },
                result.table
              );
            } else {
              if (!!currentTable && currentTable?.identifier === result.table) {
                handleChangeTable(currentTable);
              }
            }
            sheetRef.current.setSheetActive(result.table);
          }
          GlobalLoader.hide();
        },
        onCancel() {
          handleLockDatasetWithBalanceResetOriginData(result);
        },
      });
    }
  };

  const lockVersion = () => {
    lockDatasetVersionApi({
      versionId: Number(currentVersion?.id),
    }).then((res) => {
      if (res.success) {
        message.success(
          t("common.result_status", {
            title: t("dataset.actions.lock"),
            status: t("common.success"),
          })
        );
        updateCurrentVersionStatus(true);
      } else {
        let description =
          viewUtils.prettifyErrorMessage(res.message) ||
          t("common.result_status", {
            title: t("dataset.actions.lock"),
            status: t("common.fail"),
          });
        if (
          JSON.stringify(res.message).includes(
            "All tasks must be locked before locking the version"
          )
        ) {
          description = t("dataset.tips.cannot_lock");
        } else if (
          JSON.stringify(res.message).includes("The version is not valid")
        ) {
          description = t("dataset.tips.version_not_valid");
        } else if (res.status === 500) {
          description = t("dataset.tips.server_error");
        }
        message.error(description);
      }
    });
  };

  const handleActionExecute = async () => {
    setIsRunning(true);
    if (!currentVersion) {
      setIsRunning(false);
      return;
    }

    if (!currentDataset?.action_config?.pipeline_app_id) {
      message.error(t("dataset.tips.setup_pipeline"));
      setIsRunning(false);
      return;
    }

    if (currentDataset?.action_config?.flow_api_token) {
      const isTokenValid = await checkFlowApiTokenApi({
        flowApiToken: currentDataset?.action_config?.flow_api_token,
        flowWorkspaceId: currentDataset?.action_config?.flow_workspace_id,
      });
      if (!isTokenValid) {
        message.error(t("dataset.push_config.flow_api_token_invalid"));
        setIsRunning(false);
        return;
      }
    }

    const versionData =
      versionTables?.map((table: SheetTable) => ({
        ...table,
        tasks: table.tasks?.map((t: Task) => ({
          ...t,
          data: (sheetDataRef.current || {})[
            `${table.identifier}`
          ]?.tasks?.find((a: Task) => a.id === t.id)?.data,
        })),
        data: (sheetDataRef.current || {})[`${table.identifier}`]?.data || [],
      })) || [];

    const containsValueData = versionData.find((item) => {
      return item.data.length > 0;
    });

    if (!containsValueData) {
      message.error(t("dataset.tips.no_data"));
      setIsRunning(false);
      return;
    }

    if (
      currentDataset?.action_config?.pipeline_app_id ===
      "push_data_to_flowopt_apps"
    ) {
      if (!currentDataset?.action_config?.flow_app) {
        message.error(t("dataset.tips.setup_flow_app"));
        setIsRunning(false);
        return;
      } else {
        const validateDataResult = validateSchema(
          currentDataset,
          versionData,
          t,
          i18n
        );

        if (!validateDataResult?.status) {
          const messageContent = (
            <>
              {validateDataResult?.missingTables
                ?.map((item) => {
                  return t("dataset.tips.missing_table", {
                    table: item.name,
                  });
                })
                .join("、")}
              <p>
                {validateDataResult?.invalidReferences
                  ?.map((item) => {
                    return t("dataset.tips.invalid_reference", {
                      reference_column: item.referenceColumnName,
                      reference_table: item.referenceTable.name,
                      row: item.row + 2,
                      value: item.value,
                      definition_table: item.definitionTable.name,
                    });
                  })
                  .join("；")}
              </p>
            </>
          );

          notification.error({
            message: t("dataset.tips.data_validation_failed"),
            description: messageContent,
          });
          setIsRunning(false);
          return;
        }
      }
    }

    executeApi({
      versionId: Number(currentVersion?.id),
    })
      .then((res) => {
        if (res.success) {
          datasetVersionRunStatusRef.current &&
            datasetVersionRunStatusRef.current.refreshCurrentRun();
          message.success(
            t("common.result_status", {
              title: t("dataset.actions.execute"),
              status: t("common.success"),
            })
          );
        } else {
          setIsRunning(false);
          // Use a default error message
          let description = t("common.result_status", {
            title: t("dataset.actions.execute"),
            status: t("common.fail"),
          });

          // Check for specific error messages and update 'description' accordingly
          if (
            JSON.stringify(res.message).includes(
              "There is no such active app in flow"
            )
          ) {
            description = t("dataset.tips.flow_app_disabled");
          } else if (
            JSON.stringify(res.message).includes("Flow app id is not set")
          ) {
            description = t("dataset.tips.flow_app_not_set");
          } else if (
            JSON.stringify(res.message).includes("API token is not set")
          ) {
            description = t("dataset.tips.api_token_not_set");
          } else if (
            JSON.stringify(res.message).includes("The version is not valid")
          ) {
            description = t("dataset.tips.version_not_valid");
          } else {
            const factoryPattern =
              "Factory limitation is great than production plan: ";
            const warehousePattern =
              "Warehouse limitation is great than demand plan: ";
            if (res.message && res.message.includes(factoryPattern)) {
              const errorData = res.message.split(factoryPattern)[1];
              description =
                t("dataset.tips.greater_than_production") +
                "：" +
                JSON.stringify(JSON.parse(errorData));
            } else if (res.message && res.message.includes(warehousePattern)) {
              const errorData = res.message.split(warehousePattern)[1];
              description =
                t("dataset.tips.greater_than_demand") +
                "：" +
                JSON.stringify(JSON.parse(errorData));
            }
          }

          // Finally, display the error message
          message.error(description);
        }
      })
      .catch((error) => {
        message.error(
          t("common.result_status", {
            title: t("dataset.actions.execute"),
            status: t("common.fail"),
          })
        );
      });
  };

  const handleVersionExport = () => {
    if (!currentVersion?.id) {
      return;
    }
    exportDatasetVersionApi({ versionId: currentVersion?.id }).then((res) => {
      GlobalLoader.show();
      if (res.success) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = `${currentDataset?.name}-${currentVersion?.name}.xlsx`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        GlobalLoader.hide();
        link.parentNode!.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        GlobalLoader.hide();
        message.error(
          viewUtils.prettifyErrorMessage(res.message) ||
            t("common.result_status", {
              title: t("common.export"),
              status: t("common.fail"),
            })
        );
      }
    });
  };

  const handleUnLockCurrentTask = () => {
    if (currentTask) {
      updateDatasetVersionTaskApi({
        taskId: Number(currentTask.id),
        data: {
          version_id: currentVersion?.id,
          assignee: currentTask?.assignee,
          table_id: currentTable?.id,
          is_locked: false,
        },
      }).then((res) => {
        if (res.success) {
          updateCurrentTaskStatus(false);
          sheetRef.current &&
            sheetRef.current.changeSheetEditStatus(
              currentDataset?.is_admin ||
                currentTask.assignee === permission.username
            );
          message.success(
            t("common.result_status", {
              title: t("dataset.actions.unlock"),
              status: t("common.success"),
            })
          );
        } else {
          message.error(
            t("common.result_status", {
              title: t("dataset.actions.unlock"),
              status: t("common.fail"),
            })
          );
        }
      });
    }
  };

  const handleImportCurrentTable = () => {
    tableImportModalRef?.current &&
      tableImportModalRef.current.show(currentTable);
  };

  const checkAndCalUpdataData = (
    table?: SheetTable,
    task?: Task,
    data?: any
  ) => {
    if (!table || !task || !data) {
      return { status: false };
    }
    const validateDataResult = validateEmptyFields(currentTable, data);
    if (!validateDataResult?.status) {
      return validateDataResult;
    } else {
      const compareDataRet = calCompareTableDataStatistics(
        {
          id: table?.id,
          name: table?.name,
          meta: table?.meta,
          data:
            (sheetDataRef.current || {})[table?.identifier || ""]?.tasks?.find(
              (t: Task) => t.id === task?.id
            )?.data || [],
        },
        { data: data }
      );
      return {
        status: true,
        data: {
          data: data,
          ...(compareDataRet.delete_count > 0 ||
          compareDataRet.add_count > 0 ||
          compareDataRet.change_data.length > 0
            ? {
                log: compareDataRet,
              }
            : {}),
        },
      };
    }
  };

  const saveDataAndUpdate = (isLock = false) => {
    const dataRef = shouldUseForm ? sheetFormRef : sheetRef;
    const data = dataRef?.current && dataRef.current.getCurrentTableData();
    if (!currentTask) {
      message.error(
        t("common.result_status", {
          title: t("common.save"),
          status: t("common.fail"),
        })
      );
      return;
    }

    const checkRet: any = checkAndCalUpdataData(
      currentTable,
      currentTask,
      data
    );
    if (!checkRet?.status) {
      !!checkRet.missingFields &&
        notification.error({
          message: t("dataset.tips.data_validation_failed"),
          description: (
            <>
              {checkRet.missingFields?.map((item: any) => {
                return (
                  <p>
                    {t("dataset.tips.missing_field", {
                      row: item.row + 2,
                      field: item.field.name,
                    })}
                  </p>
                );
              })}
            </>
          ),
        });
      return;
    }

    updateDatasetVersionTaskDataApi({
      taskId: Number(currentTask?.id),
      data: checkRet.data,
    }).then((res) => {
      if (res.success) {
        dataRef?.current && dataRef.current.prepareValidation();
        if (isLock) {
          // 锁定数据
          updateDatasetVersionTaskApi({
            taskId: Number(currentTask.id),
            data: {
              version_id: currentVersion?.id,
              assignee: currentTask?.assignee,
              table_id: currentTable?.id,
              is_locked: true,
            },
          }).then((res) => {
            if (res.success) {
              //before refresh update task lock status
              updateCurrentTaskStatus(true);
              sheetRef.current && sheetRef.current.changeSheetEditStatus(false);
              message.success(
                t("common.result_status", {
                  title: t("dataset.actions.lock"),
                  status: t("common.success"),
                })
              );
            } else {
              message.error(
                t("common.result_status", {
                  title: t("dataset.actions.lock"),
                  status: t("common.fail"),
                })
              );
            }
            !!currentTable && resetTableData(currentTable, false);
          });
        } else {
          message.success(
            t("common.result_status", {
              title: t("common.save"),
              status: t("common.success"),
            })
          );
          !shouldUseForm &&
            !!currentTable &&
            resetTableData(currentTable, false);
          !!shouldUseForm && !!currentTable && refreshVersionSheetFormData();
        }
      } else {
        let messageStr =
          viewUtils.prettifyErrorMessage(res.message) ||
          t("common.result_status", {
            title: t("common.save"),
            status: t("common.fail"),
          });
        if (res.message?.is_valid === false && !!res.message?.errors) {
          messageStr = t("common.result_status", {
            title: t("dataset.actions.data_validation"),
            status: t("common.fail"),
          });
          dataRef?.current &&
            dataRef.current.prepareValidation(res.message.errors);
          datasetTableErrorTipsRef?.current &&
            datasetTableErrorTipsRef.current.show(res.message.errors);
        }
        message.error(messageStr);
      }
    });
  };

  const handleLockCurrentTask = () => {
    if (currentTask) {
      Modal.confirm({
        title: t("dataset.tips.confirm_task_lock"),
        content: t("dataset.tips.confirm_task_lock_desc"),
        centered: true,
        onOk() {
          saveDataAndUpdate(true);
        },
      });
    }
  };

  const handleSaveCurrentTable = () => {
    saveDataAndUpdate();
  };

  const handleExportCurrentTable = () => {
    if (!currentTable?.id || !currentVersion?.id) {
      return;
    }
    GlobalLoader.show();
    // check if the current table is primary table
    // if it's a primary table, export the whole table by backend api
    if (currentTable?.table_type === "PRIMARY") {
      // get current table view id
      exportDatasetVersionTableViewDataApi({
        versionId: currentVersion.id,
        tableViewId: currentTable.id,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const filename = `${currentDataset?.name}-${
            currentVersion?.name
          }-${currentTable?.name?.replace(`【${t("table.title")}】`, "")}.xlsx`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          GlobalLoader.hide();
          link.parentNode!.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          GlobalLoader.hide();
          message.error(
            t("common.result_status", {
              title: t("common.export"),
              status: t("common.fail"),
            })
          );
        });
    } else {
      sheetRef?.current &&
        sheetRef.current.exportCurrentTable(
          `${currentDataset?.name}-${currentVersion?.name}-${
            currentTable?.name
          }-${
            viewUtils.prettifyUsername(currentTask?.assignee_user?.name) ||
            t("common.all")
          }`
        );
      GlobalLoader.hide();
    }
  };

  const handleOpenBatchAddTask = () => {
    batchAddTaskModalRef.current && batchAddTaskModalRef.current.show();
  };

  const handleOpenBatchImport = () => {
    tableImportBatchModalRef.current &&
      tableImportBatchModalRef.current.show(versionTables);
  };

  const handleImportSubmitResult = (data: any) => {
    sheetRef.current &&
      sheetRef.current.sheetDataLoaded(
        {
          ...currentTable,
          data: data,
        },
        false,
        true
      );
  };

  const batchImportOneTable = async (
    table?: SheetTable,
    data?: any,
    save?: boolean,
    lock?: boolean
  ) => {
    if (!table || !data) {
      return false;
    }
    let my_task = (sheetDataRef.current || {})[
      `${table?.identifier}`
    ]?.tasks.find((task: Task) => task.assignee === permission.username);
    if (!my_task) {
      return false;
    }
    if (!!save || !!lock) {
      let dataRet: any = checkAndCalUpdataData(table, my_task, data);
      if (!dataRet.status) {
        return false;
      }
      let saveRet = await updateDatasetVersionTaskDataApi(
        {
          taskId: Number(my_task?.id),
          data: dataRet.data,
        },
        true
      );
      if (!saveRet.success) {
        return false;
      }
    }
    if (sheetDataRef.current[`${table?.identifier}`].tasks?.length === 1) {
      sheetDataRef.current[`${table?.identifier}`].data = data;
    }
    sheetDataRef.current[`${table?.identifier}`].tasks = sheetDataRef.current[
      `${table?.identifier}`
    ].tasks.map((task: Task) =>
      task.id === my_task?.id
        ? {
            ...task,
            data: data,
          }
        : task
    );
    (table?.current_task?.id === my_task?.id ||
      (sheetDataRef.current[`${table?.identifier}`].tasks?.length === 1 &&
        !table?.current_task)) &&
      sheetRef.current &&
      sheetRef.current.sheetDataLoaded(
        {
          ...table,
          data: data,
        },
        true,
        true
      );
    sheetDataRef.current[`${table?.identifier}`].tasks?.length > 1 &&
      resetTableData(table!, !table?.current_task);
    if (!!lock) {
      let lockRet = await updateDatasetVersionTaskApi(
        {
          taskId: Number(my_task.id),
          data: {
            version_id: currentVersion?.id,
            assignee: my_task?.assignee,
            table_id: my_task?.id,
            is_locked: true,
          },
        },
        true
      );
      if (!lockRet.success) {
        return false;
      }
      updateCurrentTaskStatus(true, table?.identifier, my_task?.id);
      table?.current_task?.id === my_task?.id &&
        sheetRef.current &&
        sheetRef.current.changeSheetEditStatus(false, table?.identifier);
    }
    return true;
  };

  const handleBatchImportSubmitResult = async (
    data: SheetTable[],
    save: boolean = false,
    lock: boolean = false
  ) => {
    let errorRet = [];
    tableImportBatchModalRef.current &&
      tableImportBatchModalRef.current.close();
    for (let i = 0, len = data.length; i < len; i++) {
      let table = versionTables?.find(
        (t: SheetTable) => t.identifier === data[i].identifier
      );
      let importRet = await batchImportOneTable(
        table,
        data[i].data,
        save,
        lock
      );
      if (!importRet) {
        errorRet.push(
          t("table.import.import_failed", {
            table: table?.name,
            action: !!lock ? t("dataset.actions.lock") : t("common.save"),
          })
        );
      }
    }
    if (errorRet?.length > 0) {
      message.error(errorRet.join(";"));
    } else {
      message.success(
        t("common.result_status", {
          title: t(
            lock
              ? "table.import.import_and_lock"
              : save
              ? "table.import.import_and_save"
              : "table.import.batch_import"
          ),
          status: t("common.success"),
        })
      );
    }
    GlobalLoader.hide();
  };

  const renderSheetView = useMemo(
    () => (
      <TablesSheet
        ref={sheetRef}
        id="luckysheet-dataset-version-data"
        tables={initSheetData}
        changeTableCallback={handleChangeTable}
        minHeight="calc(100vh - 168px)"
      />
    ),
    // eslint-disable-next-line
    [initSheetData]
  );

  const renderSheetFormView = useMemo(
    () => (
      <TablesSheetForm
        ref={sheetFormRef}
        tables={versionTables}
        changeTableCallback={(table: SheetTable) => handleChangeTable(table)}
      />
    ),
    // eslint-disable-next-line
    [versionTables]
  );

  return (
    <>
      <NavHyperLink
        showBack={true}
        items={[
          {
            title: t("dataset.title"),
            path: `${IsSimpleLayout ? "/simple" : ""}/dataset`,
          },
          { title: currentDataset?.name || "" },
        ]}
      />
      {currentDataset?.is_admin && (
        <DatasetBatchAddTaskModal
          ref={batchAddTaskModalRef}
          versionTables={versionTables}
          currentVersion={currentVersion}
          updateCallback={handleCurrentTableTasksUpdateCallback}
        />
      )}
      <TableImportModal
        ref={tableImportModalRef}
        submitCallback={handleImportSubmitResult}
        handleOpenBatchImport={handleOpenBatchImport}
      />
      <TableImportBatchModal
        ref={tableImportBatchModalRef}
        submitCallback={handleBatchImportSubmitResult}
      />
      <DatasetVersionRunStatus
        ref={datasetVersionRunStatusRef}
        currentVersion={currentVersion}
        refreshCallback={resetVersionTablesData}
        statusChangeCallback={setIsRunning}
      />
      <div
        className="layout-content-box"
        style={
          shouldUseForm
            ? {
                background: "transparent",
                margin: 0,
                height: "calc(100% - 32px)",
                position: "relative",
                marginLeft: "-10px",
                marginRight: "-10px",
                padding: "0 0 5em 0",
              }
            : {
                display: "flex",
                padding: 0,
                position: "relative",
              }
        }
      >
        {(!currentDataset || !versionList) && (
          <div
            style={{ textAlign: "center", padding: "10vh 0", width: "100%" }}
          >
            {!!currentDataset && !currentDataset?.id ? (
              <Empty description={t("common.no_permission")} />
            ) : (
              <Spin />
            )}
          </div>
        )}
        {!!currentVersion?.id && shouldUseForm && (
          <>
            {renderSheetFormView}
            {!!currentTask &&
              !!currentTable &&
              currentTable?.table_type === "COMMON" && (
                <div
                  style={{
                    position: "fixed",
                    bottom: "1em",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={
                      currentTask.is_locked
                        ? t("dataset.tips.table_is_locked")
                        : ""
                    }
                  >
                    <Button
                      disabled={currentTask.is_locked}
                      danger
                      type="primary"
                      onClick={handleLockCurrentTask}
                      style={{
                        margin: "0 5px",
                      }}
                    >
                      {t(
                        `dataset.actions.${
                          currentTask.is_locked ? "unlock" : "lock"
                        }`
                      )}
                    </Button>
                  </Tooltip>
                  {!currentTask.is_locked && (
                    <>
                      <Button
                        type="primary"
                        style={{ margin: "0 5px" }}
                        onClick={handleSaveCurrentTable}
                      >
                        {t("common.save")}
                      </Button>
                    </>
                  )}
                </div>
              )}
          </>
        )}
        {!shouldUseForm && !!currentDataset && !!versionList && (
          <>
            <div
              className="layout-content-box"
              style={{
                position: "relative",
                flex: 1,
                marginTop: 0,
                paddingBottom: 0,
              }}
            >
              <div style={{ minWidth: "1000px" }}>
                <div style={{ position: "relative", background: "white" }}>
                  <div
                    className="sheet-actions"
                    style={{
                      zIndex: 999,
                      position: "absolute",
                      right: 3,
                      top: 10,
                      marginRight: "10px",
                      alignItems: "center",
                      display:
                        !!initSheetData && initSheetData.length > 0
                          ? "flex"
                          : "none",
                    }}
                  >
                    {currentDataset?.is_admin &&
                      !!currentTable &&
                      currentTable?.table_type === "COMMON" && (
                        <DatasetOperators
                          table={currentTable}
                          version={currentVersion}
                          updateCallback={handleCurrentTableTasksUpdateCallback}
                          handleOpenBatchAddTask={handleOpenBatchAddTask}
                          changeTask={{
                            onSelect: handleChangeTask,
                            value: currentTask?.id || "all",
                            options: [
                              { value: "all", label: t("common.all") },
                              ...(currentTable?.tasks || []).map(
                                (item: Task) => ({
                                  value: item?.id,
                                  label:
                                    viewUtils.prettifyUsername(
                                      item?.assignee_user?.name
                                    ) +
                                    "(" +
                                    item?.assignee_user?.username +
                                    ")",
                                })
                              ),
                            ],
                          }}
                        />
                      )}
                    {currentDataset?.can_visit &&
                      !currentDataset?.is_admin &&
                      (currentTable?.tasks || []).length > 0 &&
                      currentTable?.table_type === "COMMON" && (
                        <div className="sheet-action-item">
                          <Select
                            suffixIcon={<CaretDownOutlined />}
                            size="small"
                            style={{ width: "12em" }}
                            showSearch={true}
                            placeholder={t("dataset.tips.select_user")}
                            onSelect={handleChangeTask}
                            value={currentTask?.id || "all"}
                            options={[
                              { value: "all", label: t("common.all") },
                              ...(currentTable?.tasks || []).map(
                                (item: Task) => ({
                                  value: item?.id,
                                  label:
                                    viewUtils.prettifyUsername(
                                      item?.assignee_user?.name
                                    ) +
                                    "(" +
                                    item?.assignee_user?.username +
                                    ")",
                                })
                              ),
                            ]}
                          />
                        </div>
                      )}
                    {!!currentTask &&
                      !!currentTable &&
                      currentTable?.table_type === "COMMON" && (
                        <>
                          {!currentTask.is_locked && (
                            <div className="sheet-action-item">
                              <Button
                                size="small"
                                icon={
                                  <IconLock style={{ marginRight: "-3px" }} />
                                }
                                onClick={handleLockCurrentTask}
                              >
                                {t("dataset.actions.lock")}
                              </Button>
                            </div>
                          )}
                          {currentTask.is_locked && (
                            <Tooltip
                              title={
                                !currentDataset?.is_admin
                                  ? t("dataset.tips.table_is_locked")
                                  : currentVersion?.is_locked
                                  ? t("dataset.tips.need_unlock")
                                  : ""
                              }
                            >
                              <div className="sheet-action-item">
                                <Button
                                  disabled={
                                    currentVersion?.is_locked ||
                                    !currentDataset?.is_admin
                                  }
                                  size="small"
                                  icon={
                                    <IconUnlock
                                      style={{ marginRight: "-3px" }}
                                    />
                                  }
                                  onClick={handleUnLockCurrentTask}
                                >
                                  {t("dataset.actions.unlock")}
                                </Button>
                              </div>
                            </Tooltip>
                          )}
                          {!currentTask.is_locked && (
                            <div className="sheet-action-item">
                              <Button
                                size="small"
                                icon={
                                  <IconSave style={{ marginRight: "-3px" }} />
                                }
                                onClick={handleSaveCurrentTable}
                              >
                                {t("common.save")}
                              </Button>
                            </div>
                          )}
                          {!currentTask.is_locked && (
                            <div className="sheet-action-item">
                              <Button
                                size="small"
                                icon={
                                  <IconImport style={{ marginRight: "-3px" }} />
                                }
                                onClick={handleImportCurrentTable}
                              >
                                {t("common.import")}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    <div className="sheet-action-item">
                      <Button
                        size="small"
                        icon={<IconExport style={{ marginRight: "-3px" }} />}
                        onClick={handleExportCurrentTable}
                      >
                        {t("common.export")}
                      </Button>
                    </div>
                  </div>
                  {renderSheetView}
                  <TableSheetErrorTips ref={datasetTableErrorTipsRef} />
                </div>
              </div>
            </div>
            <div className="dataset-data-actions">
              <DatasetVersionAction
                currentDataset={currentDataset}
                currentVersion={currentVersion}
                versionList={versionList}
                updateVersion={handleUpdateVersion}
                deleteVersion={handleDeleteVersion}
                changeCurrentVersion={handleChangeCurrentVersion}
              />
              {currentDataset?.is_admin && (
                <DatasetVersionProgress versionTables={versionTables || []} />
              )}
              <DatasetVersionCompare
                versionList={versionList}
                currentVersion={currentVersion}
                tables={(versionTables || [])
                  .filter((table: SheetTable) => table.table_type !== "PRIMARY")
                  .map((table: SheetTable) => ({
                    name: table.name,
                    id: table.id,
                  }))}
              />
              {currentDataset?.is_admin && (
                <DatasetShare dataset={currentDataset} />
              )}
              {currentDataset?.is_admin && (
                <div className="action-item">
                  <Button
                    icon={
                      currentVersion?.is_locked ? (
                        <IconUnlock
                          style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
                        />
                      ) : (
                        <IconLock
                          style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
                        />
                      )
                    }
                    onClick={
                      currentVersion?.is_locked
                        ? handleUnLockDataset
                        : handleLockDataset
                    }
                  >
                    {t(
                      `dataset.actions.${
                        currentVersion?.is_locked ? "unlock" : "lock"
                      }`
                    )}
                  </Button>
                </div>
              )}
              {currentDataset?.is_admin && (
                <Tooltip
                  title={
                    !currentVersion?.is_locked
                      ? t("dataset.tips.need_lock_dataset")
                      : !currentDataset?.action_config
                      ? t("dataset.tips.need_push_config")
                      : ""
                  }
                >
                  <div className="action-item">
                    <Button
                      icon={
                        <IconRocket
                          style={{
                            fontSize: "1.4em",
                            marginBottom: "0.1em",
                          }}
                        />
                      }
                      disabled={
                        !currentVersion?.is_locked ||
                        !currentDataset?.action_config ||
                        isRunning
                      }
                      onClick={handleActionExecute}
                    >
                      {t("dataset.actions.execute")}
                    </Button>
                  </div>
                </Tooltip>
              )}
              {currentDataset?.is_admin && (
                <DatasetChart version={currentVersion} />
              )}
              {currentDataset?.is_admin && (
                <DatasetActionHistory version={currentVersion} />
              )}
              {currentDataset?.is_admin && (
                <DatasetActionConfig
                  dataset={currentDataset}
                  updateCallback={updateDataset}
                />
              )}

              <div className="action-item">
                <Button
                  icon={
                    <IconExport
                      style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
                    />
                  }
                  onClick={handleVersionExport}
                >
                  {t("common.export")}
                </Button>
              </div>
              {enableUseForm && (
                <div className="action-item">
                  <Button
                    icon={
                      <ProfileOutlined
                        style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
                      />
                    }
                    onClick={() => {
                      navigate(
                        `${
                          IsSimpleLayout ? "/simple" : ""
                        }/dataset/data/${dataset_id}?mode=form`,
                        { replace: true }
                      );
                    }}
                  >
                    {t("common.form_mode")}
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DatasetDataPage;
